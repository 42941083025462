import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import Modal from "react-bootstrap/Modal";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";
import AuthContext from "../context/AuthContext";
import FiberCoresEdit from "./FiberCoresEdit";
// For API Requests
import axios from "axios";

function FiberCoresTable() {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleshowDetails = () => setShow(true);
  const handlecloseDetails = () => setShow(false);
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [Details, setDetails] = useState({
    core_id: 0,
    sticker: "",
    type: "",
    status: "",
    mgrs: "",
  });
  const updateDetails = (details) => {
    setDetails(details);
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/radius/fiber/cores/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
        },
      });

      if (response.status === 200) {
        setData(response.data.reverse());
        console.log(response.data.reverse());
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [user.username, authTokens.access]);

  const columns = [
    { field: "code", headerName: "Name", flex: 2, minWidth: 150 },
    { field: "status", headerName: "Status", flex: 2, minWidth: 150 },
    { field: "parent_core", headerName: "Parent", flex: 2, minWidth: 150 },
    { field: "sticker", headerName: "Sticker", flex: 2, minWidth: 150 },

    {
      field: "mgrs",
      headerName: "Location",
      minWidth: 230,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 100,
    },

    {
      field: "actions",
      headerName: "Actions",
      minWidth: 230,
      flex: 2,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              const Details = {
                core_id: params.row.core_id,
                mgrs: params.row.mgrs,
                status: params.row.status,
                type: params.row.type,
                sticker: params.row.sticker,
              };
              updateDetails(Details);
              handleshowDetails();
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.core_id;

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  const CustomFooter = () => (
    <GridFooterContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "10px",
        }}
      >
        <GridPagination />
      </div>
    </GridFooterContainer>
  );

  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={show} onHide={handlecloseDetails} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Core</Modal.Title>
        </Modal.Header>
        <div className="Subform">
          <FiberCoresEdit
            type={Details.type}
            core_id={Details.core_id}
            mgrs={Details.mgrs}
            status={Details.status}
            sticker={Details.sticker}
            closeModal={handlecloseDetails}
            fetch={fetchData}
          />
        </div>
      </Modal>
    </div>
  );
}

export default FiberCoresTable;
