import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import CustomizedActions from "./BulkActions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import AuthContext from "../../context/AuthContext";
import axios from "axios";

import Notes from "./notes";
import ReceiptTable from "./ReceiptsTable";
import Notification from "./SendNotification";

function Collector() {
  const { user } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [Client, setClient] = useState("");
  const [Fullname, setFullname] = useState("");

  const [Cl, setCl] = useState("");
  const [checkedRows, setCheckedRows] = useState([]);
  const [show, setShow] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);

  // New state for postpone modal
  const [showPostponeModal, setShowPostponeModal] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);

  const CloseCollect = () => setShow(false);
  const ShowCollect = () => setShow(true);

  const CloseNotification = () => setShowNotification(false);
  const ShowNotification = () => setShowNotification(true);

  const CloseReceipt = () => setShowReceipt(false);
  const ShowReceipt = () => setShowReceipt(true);

  const ClosePostponeModal = () => setShowPostponeModal(false);
  const ShowPostponeModal = (sub_id) => {
    setSelectedSubscriptionId(sub_id);
    setShowPostponeModal(true);
  };

  const handlePostponeConfirm = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.post(
        `${API_URL}/accounting/info/Postpone/`,
        {
          action: "postpone",
          sub_id: selectedSubscriptionId,
          username: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (response.status === 200) {
        fetchData(); // Refresh data after successful postpone
        setShowPostponeModal(false);
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(
        `${API_URL}/accounting/info/Collector/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        }
      );

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user.username]);

  const getStatusCellStyle = (params) => {
    let statusColor = "";
    switch (params.value) {
      case "offline":
        statusColor = "status-offline";
        break;
      case "online":
        statusColor = "status-online";
        break;
      case "inactive":
        statusColor = "status-inactive";
        break;
      case "expired":
        statusColor = "status-expired";
        break;
      case "hold":
        statusColor = "status-hold";
        break;
      default:
        break;
    }
    return statusColor;
  };

  const columns = [
    {
      field: "checkbox",
      headerName: "",
      width: 30,
      renderCell: (params) => (
        <Checkbox
          className="subcheck"
          checked={checkedRows.includes(params.row.subscription_id)}
          onChange={(event) =>
            handleCheckboxChange(event, params.row.subscription_id)
          }
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      minWidth: 100,
      cellClassName: (params) => getStatusCellStyle(params),
    },
    {
      field: "fullname_en",
      headerName: "Full Name",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "suffix",
      headerName: "Suffix",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "balance_usd",
      headerName: "Balance (USD)",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "balance",
      headerName: "Initial Balance",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "client_notes",
      headerName: "Client Notes",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "phone_number",
      headerName: "Phone",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "village_name",
      headerName: "Village",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "address_string",
      headerName: "Address",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => formatDate(params.row.payment_date),
    },
    {
      field: "due",
      headerName: "Due",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "expiration_date",
      headerName: "Expiration Date",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => formatDate(params.row.expiration_date),
    },
    {
      field: "collector",
      headerName: "Collector",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "label",
      headerName: "Label",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "note_details",
      headerName: "Latest Note",
      flex: 2,
      minWidth: 250,
      valueGetter: (params) => {
        const { note_user, latest_note } = params.row;
        return note_user && latest_note
          ? `${note_user}: ${latest_note}`
          : "No Note";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 300,
      flex: 2,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              setClient(params.row.subscription_id);
              setFullname(params.row.fullname_en);
              ShowCollect();
            }}
          >
            Notes
          </button>
          <button
            className="edit-client"
            onClick={() => {
              setCl(params.row.client_id);
              ShowReceipt();
            }}
          >
            Receipt
          </button>
          <button
            className="edit-client"
            onClick={() => {
              setClient(params.row.subscription_id);
              ShowNotification();
            }}
          >
            Notifications
          </button>
          <button
            className="edit-client postpone-btn"
            onClick={() => ShowPostponeModal(params.row.subscription_id)}
          >
            Postpone
          </button>
        </div>
      ),
    },
  ];
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };
  const getRowId = (row) => row.subscription_id;

  const handleCheckboxChange = (event, subscriptionId) => {
    const checked = event.target.checked;
    setCheckedRows((prevCheckedRows) => {
      if (checked) {
        return [...prevCheckedRows, subscriptionId];
      } else {
        return prevCheckedRows.filter((id) => id !== subscriptionId);
      }
    });
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data
    .filter((row) => row.balance_usd > 0)
    .filter((row) => {
      const searchWords = searchQuery.split(" ");
      return searchWords.every((word) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(word.toLowerCase().trim())
        )
      );
    });

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  return (
    <div className="InternetTable">
      <div className="actions">
        <CustomizedActions
          subscriptions={checkedRows}
          deselect={() => setCheckedRows([])}
          fetch={fetchData}
        />
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
          className="search-accounting"
        />
      </div>
      {!loading && data.length > 0 && (
        <div style={{ height: 570, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            rowsPerPage={10}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}

      {/* Postpone Confirmation Modal */}
      <Modal show={showPostponeModal} onHide={ClosePostponeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Postpone</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to postpone this subscription?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ClosePostponeModal}>
            No
          </Button>
          <Button variant="primary" onClick={handlePostponeConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={CloseCollect} className="modal-lg">
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>{Fullname} Notes</Modal.Title>
          </Modal.Header>
          <Notes sub_id={Client} />
        </div>
      </Modal>
      <Modal
        show={showNotification}
        onHide={CloseNotification}
        className="modal-lg"
      >
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Notification</Modal.Title>
          </Modal.Header>
          <Notification sub_id={Client} />
        </div>
      </Modal>
      <Modal show={showReceipt} onHide={CloseReceipt} className="modal-lg">
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>{Fullname} Receipts</Modal.Title>
          </Modal.Header>
          <ReceiptTable client_id={Cl} />
        </div>
      </Modal>
    </div>
  );
}

export default Collector;
