import React, { useState, useContext, useEffect } from "react";
import Axios from "axios";
import AuthContext from "../../context/AuthContext";
import { API_URL } from "../../components/api";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import MCCheck from "./MCCheck"; // Import the SmallMenu component

function Collect(props) {
  const [exchangeRate, setExchangeRate] = useState(0);
  const [paidUSD, setPaidUSD] = useState(0);
  const [paidLBP, setPaidLBP] = useState(0);
  const [returnedLBP, setReturnedLBP] = useState(0);
  const [returnedUSD, setReturnedUSD] = useState(0);
  const [discountLBP, setDiscountLBP] = useState(0);
  const [discountUSD, setDiscountUSD] = useState(0);
  const [authTokens] = useState(() =>
    JSON.parse(localStorage.getItem("authTokens"))
  );
  const { user } = useContext(AuthContext);
  const client_id = props.client_id;
  const balance = parseFloat(props.balance_usd); // ensure balance is a number
  const [remainingBalance, setRemainingBalance] = useState(balance);
  const [showSmallMenu, setShowSmallMenu] = useState(true); // State to toggle SmallMenu

  console.log(client_id);
  console.log(balance);
  console.log(remainingBalance);

  const collectClose = () => props.handleClose(); // close the collect modal

  useEffect(() => {
    let totalPaidUSD = parseFloat(paidUSD);
    let totalPaidLBP = parseFloat(paidLBP) / parseFloat(exchangeRate);

    if (isNaN(totalPaidUSD)) totalPaidUSD = 0;
    if (isNaN(totalPaidLBP)) totalPaidLBP = 0;

    let totalDiscountUSD = parseFloat(discountUSD);
    let totalDiscountLBP = parseFloat(discountLBP) / parseFloat(exchangeRate);

    if (isNaN(totalDiscountUSD)) totalDiscountUSD = 0;
    if (isNaN(totalDiscountLBP)) totalDiscountLBP = 0;

    let totalReturnedUSD = parseFloat(returnedUSD);
    let totalReturnedLBP = parseFloat(returnedLBP) / parseFloat(exchangeRate);

    if (isNaN(totalReturnedUSD)) totalReturnedUSD = 0;
    if (isNaN(totalReturnedLBP)) totalReturnedLBP = 0;

    const totalPaid = totalPaidUSD + totalPaidLBP;
    const totalDiscount = totalDiscountUSD + totalDiscountLBP;
    const totalReturned = totalReturnedUSD + totalReturnedLBP;
    const remaining = balance - totalPaid - totalDiscount + totalReturned;

    setRemainingBalance(remaining); // Allow remaining balance to be negative
  }, [
    paidUSD,
    paidLBP,
    discountUSD,
    discountLBP,
    returnedUSD,
    returnedLBP,
    exchangeRate,
    balance,
  ]);

  const handleExchangeRateChange = (event) => {
    setExchangeRate(event.target.value);
  };

  const handlePaidUSDChange = (event) => {
    setPaidUSD(event.target.value);
  };

  const handlePaidLBPChange = (event) => {
    setPaidLBP(event.target.value);
  };

  const handleReturnedLBPChange = (event) => {
    setReturnedLBP(event.target.value);
  };

  const handleReturnedUSDChange = (event) => {
    setReturnedUSD(event.target.value);
  };

  const handleDiscountLBPChange = (event) => {
    setDiscountLBP(event.target.value);
  };

  const handleDiscountUSDChange = (event) => {
    setDiscountUSD(event.target.value);
  };

  const handlePostRequest = async () => {
    try {
      const requestData = {
        client_id: client_id,
        paid_usd: paidUSD,
        paid_lbp: paidLBP,
        returned_usd: returnedUSD,
        returned_lbp: returnedLBP,
        discount_usd: discountUSD,
        discount_lbp: discountLBP,
        exchange_rate: exchangeRate,
        total_paid: remainingBalance, // Total paid is the difference between initial balance and remaining balance
        userdealer: user.username,
      };

      const response = await Axios.post(
        `${API_URL}/accounting/collect/`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      console.log(response);
      // Close the collect modal and show the receipt modal
      collectClose();
      props.handleOpen();
      props.fetch();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="collect-container">
      <div className="small-menu-toggle">
        <button
          onClick={() => setShowSmallMenu(!showSmallMenu)}
          className="btn btn-secondary mt-3"
        >
          {showSmallMenu ? "Hide" : "Check MC Shield"}
        </button>
        {showSmallMenu && <MCCheck client_id={client_id} />}{" "}
        {/* Display the SmallMenu component when showSmallMenu is true */}
      </div>
      <h5 className="collect-heading">Please enter the payment details</h5>
      <div className="row collect-form">
        {/* USD Column */}
        <div className="col-md-6">
          <label className="collect-label">
            Paid Amount in USD:
            <input
              type="number"
              step="0.01"
              min="0"
              value={paidUSD}
              onChange={handlePaidUSDChange}
              className="form-control collect-input darker-bg"
            />
          </label>
          <label className="collect-label">
            Returned Amount in USD:
            <input
              type="number"
              step="0.01"
              min="0"
              value={returnedUSD}
              onChange={handleReturnedUSDChange}
              className="form-control collect-input darker-bg"
            />
          </label>
          <label className="collect-label">
            Discount in USD:
            <input
              type="number"
              step="0.01"
              min="0"
              value={discountUSD}
              onChange={handleDiscountUSDChange}
              className="form-control collect-input darker-bg"
            />
          </label>
        </div>

        {/* LBP Column */}
        <div className="col-md-6">
          <label className="collect-label">
            Paid Amount in LBP:
            <input
              type="number"
              step="0.01"
              min="0"
              value={paidLBP}
              onChange={handlePaidLBPChange}
              onWheel={(e) => e.target.blur()} // Disable scrolling when the mouse wheel is used
              className="form-control collect-input darker-bg"
            />
          </label>
          <label className="collect-label">
            Returned Amount in LBP:
            <input
              type="number"
              step="0.01"
              min="0"
              value={returnedLBP}
              onChange={handleReturnedLBPChange}
              className="form-control collect-input darker-bg"
            />
          </label>
          <label className="collect-label">
            Discount in LBP:
            <input
              type="number"
              step="0.01"
              min="0"
              value={discountLBP}
              onChange={handleDiscountLBPChange}
              className="form-control collect-input darker-bg"
            />
          </label>
          <label className="collect-label">
            Exchange Rate:
            <input
              type="number"
              step="0.01"
              min="0"
              value={exchangeRate}
              onChange={handleExchangeRateChange}
              className="form-control collect-input"
            />
          </label>
        </div>
      </div>

      <label className="collect-label">
        Remaining Balance:
        <input
          type="text"
          value={remainingBalance.toFixed(2)}
          readOnly
          className="form-control collect-input darker-bg"
        />
      </label>

      <div className="collect-submit">
        <button onClick={handlePostRequest} className="btn btn-primary">
          Submit
        </button>
      </div>
    </div>
  );
}

export default Collect;
